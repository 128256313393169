<template>
	<form class="form-inline search-form" @submit.prevent="onSearchClick">
		<input
				v-model.lazy="search"
				v-debounce="delay"
				:class="{ progress: triggers.data_is_fetching }"
				placeholder="What can we help you find?"
				maxlength="400"
				name="query"
				type="text"
				class="form-control"
				autocomplete="off"
				@change="onChange"
				@keyup.enter="onSearchClick"
		>
		<button class="btn btn-default js-search-btn" type="submit"></button>
	</form>
</template>

<script>
	const MAX_SYMBOLS_LENGTH = 3;

	export default {
		name: 'SearchForm',
		props: {
			triggers: {
				'type': Object,
				'default': () => ({})
			},
			delay: {
				'type': Number,
				'default': 500
			}
		},
		data: () => ({
			search: '',
			progress: {
				data_is_fetching: false
			}
		}),
		watch: {
			'triggers.clear_search'() {
				this.clear();
			},

			'triggers.data_is_fetching'() {
				this.progress.data_is_fetching = !this.progress.data_is_fetching;
			}
		},
		methods: {
			clear() {
				this.search = '';
			},
			onChange() {
				if(!this.triggers.data_is_fetching && this.search.length >= MAX_SYMBOLS_LENGTH ) {
					this.$emit('change', this.search);
				}
			},
			onSearchClick() {
				if (this.search) {
					this.$emit('searchClick', this.search);
				}
			}
		}
	};
</script>
