/* global Cleave */

import Vue from 'vue';

function debounce(func, delay) {
	let timeout;

	return function(...args) {
		const context = this;

		clearTimeout(timeout);
		timeout = setTimeout(() => func.apply(context, args), delay);
	};
}

function createNewEvent(eventName) {
	const is_new_browsers = typeof(Event) === 'function';
	const event = is_new_browsers ? new Event(eventName) : document.createEvent('Event');

	if (!is_new_browsers) {
		event.initEvent(eventName, true, true);
	}

	return event;
}

Vue.directive('debounce', {
	inserted(el, { value, oldValue}) {
		if (value !== oldValue) {
			el.oninput = debounce(function() {
				el.dispatchEvent(createNewEvent('change'));
			}, parseInt(value) || 500);
		}
	}
});

Vue.directive('focus', {
	inserted(el) {
		el.focus();
	}
});

/**
 * @Directive phone-mask
 * Source cleve config
 * https://github.com/nosir/cleave.js/blob/master/doc/options.md#general-config
 */
Vue.directive('phone-mask', {
	inserted(el, binding) {
		const defaultOpts = {
			phone: true,
			phoneRegionCode: '{country}',
			numericOnly: true,
			blocks: [0, 3, 3, 4],
			delimiter: '-',
			delimiters: ['(', ') ', '-'],
			prefix: null,
			onValueChanged: function () {
				// e.target = { value: '5100-1234', rawValue: '51001234' }
				// Override here your method
			}
		};

		const opts = (binding.value && Object.keys(binding.value).length > 0) ?
				binding.value :
				{};

		const selector = (binding.value && binding.value.inputName && binding.value.inputName.length > 0) ?
				// 1. Check name in options in input field
				`input[name="${binding.value.inputName}"]` :
				// 2. Check class in input field
				(el.getAttribute('class')) ?
						`.${el.getAttribute('class')}` :
				// 3. Set default value
				'input[name="telephone"]';

		try {
			new Cleave(selector, {...defaultOpts, ...opts});
		} catch (err) {
			//console.error('Can\'t create instance new Cleave in default selector ' + selector);
			throw new Error(err);
		}
	}
});

Vue.directive('click-outside', {
	bind: function (el, binding, vnode) {
		el.clickOutsideEvent = function (event) {
			// here I check that click was outside the el and his childrens
			if (!(el == event.target || el.contains(event.target))) {
				// and if it did, call method provided in attribute value
				vnode.context[binding.expression](event);
			}
		};
		document.body.addEventListener('click', el.clickOutsideEvent);
	},
	unbind: function (el) {
		document.body.removeEventListener('click', el.clickOutsideEvent);
	}
});

