import axios from 'axios';

axios.interceptors.request.use( function (config) {
	window.jQuery && window.jQuery.active++;
	return config;
}, function (error) {
	return Promise.reject(error);
});

axios.interceptors.response.use( function (response) {
	window.jQuery && --window.jQuery.active;
	return response;
}, function (error) {
	return Promise.reject(error);
});

export default axios;