<template>
	<div v-click-outside="hideSearchList" class="search-form-wrapper">
		<search-form
				:triggers="triggers"
				@change="onChange"
				@searchClick="searchClick"
		/>

		<search-list
				v-if="use_search_list && is_show_search_list"
				:search_items="search_items"
		/>
	</div>
</template>

<script>
	import axios from '@modules-vue/components/core/axios-config.js';
	import {urls} from './api';

	import '@modules-vue/components/core/directives';

	import SearchForm from './components/search-form.vue';
	import SearchList from './views/search-list.vue';

	export default {
		name: 'QuickSearchView',
		components: {
			SearchList,
			SearchForm
		},
		data: () => ({
			search_items: null,
			query_string: null,
			is_show_search_list: false,
			triggers: {
				data_is_fetching: false
			}
		}),
		computed: {
			use_search_list() {
				return this.query_string && this.search_items === Object(this.search_items) && Object.keys(this.search_items).length > 0;
			}
		},
		methods: {
			onChange(query_string) {
				this.showSearchList();
				this.getSearchItems(query_string);
			},
			hideSearchList() {
				this.is_show_search_list = false;
			},
			showSearchList() {
				this.is_show_search_list = true;
			},
			searchClick(search) {
				window.location.href = `/search/?query=${search}`;
			},
			getSearchItems(query_string) {
				this.query_string = query_string;
				if (query_string && !this.triggers.data_is_fetching) {

					this.triggers.data_is_fetching = true;

					const url = urls.list(query_string);

					axios
							.get(url)
							.then(res => res.data)
							.then(search_items => {
								this.search_items = search_items;
								this.triggers.data_is_fetching = false;
							});
				}
			}
		}
	};
</script>