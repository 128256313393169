<template>
	<div v-if="is_show_list" class="search-drop">
		<div class="search-drop-container">
			<div class="search-drop-content-wrap">
				<div class="search-drop-content">
					<div v-if="is_show_products" class="search-drop-products">
						<div class="row flex-wrap products-grid js-app-carousel js-active">
							<div
									v-for="(product, index) in search_items.products"
									:key="index"
									class="col-xs-12 col-sm-6 col-md-4 col-lg-3 products-grid-item"
							>
								<div class="img-zone">
									<a :href="product.product_url" class="img">
										<img :alt="product.prodname" :src="`/pthumbs/${product.prodimage}`">
									</a>
								</div>
								<div class="desc-zone">
									<div class="price">
										{{ product.price | money }}
									</div>
									<a :href="product.product_url" class="name h4">{{ product.prodname }}</a>
								</div>
							</div>
						</div>
						<a :href="view_more_url" class="more">View more</a>
					</div>

					<div v-if="is_show_catalogs" class="search-drop-catalogs">
						<h3>Categories</h3>
						<ul>
							<template v-for="(catalog, index) in search_items.catalogs">
								<li v-if="!catalog.half" :key="index">
									<a :href="catalog.url">{{ catalog.catname }}</a>
								</li>
							</template>
						</ul>
					</div>
				</div>
			</div>
			<template v-if="is_show_searches || is_show_manufacturers">
				<div class="search-drop-side">
					<ul class="search-drop-searches">
						<li	v-if="search_items.raw_query">
							<a :href="`/search/?query=${search_items.raw_query}`">
								<b>{{ search_items.raw_query }}</b>
							</a>
						</li>
						<template v-if="is_show_manufacturers">
							<li
									v-for="(manufacturer, index) in search_items.manufacturers"
									:key="index"
									class="brand"
							>
								<a :href="manufacturer.url">
									<img :src="`/crm/userfiles/images/thumbs/${manufacturer.manufacturer_logo}`" alt="">
									<span v-html="highlightMatches(manufacturer.catname, search_items.raw_query)"></span>
								</a>
							</li>
						</template>
						<template v-if="is_show_searches">
							<li	v-for="(search, index) in search_items.searches" :key="index">
								<a :href="search.url">
									<span v-html="highlightMatches(search.catname, search_items.raw_query)"></span>
								</a>
							</li>
						</template>
					</ul>
				</div>
			</template>
		</div>
	</div>

	<span v-else class="search-drop search-drop-empty text-center">
		Sorry, your search did not match any products.
	</span>
</template>

<script>
	/* global jQuery */

	import '@modules-vue/components/core/filters';

	export default {
		name: 'SearchList',
		props: {
			search_items: {
				'type': Object,
				'default': () => ({})
			}
		},
		computed: {
			is_show_products() {
				return this.search_items.products && this.search_items.products.length > 0;
			},
			is_show_catalogs() {
				return this.search_items.catalogs && this.search_items.catalogs.length > 0;
			},
			is_show_manufacturers() {
				return this.search_items.manufacturers && this.search_items.manufacturers.length > 0;
			},
			is_show_searches() {
				return this.search_items.searches && this.search_items.searches.length > 0;
			},
			is_show_list() {
				return this.is_show_products || this.is_show_catalogs || this.is_show_manufacturers || this.is_show_searches;
			},
			view_more_url() {
				return `/search/${this.search_items.raw_query.replace(/\//g, '')}.html`;
			}
		},
		watch: {
			'search_items.products'() {
				this.initCarousel();
			}
		},
		mounted() {
			this.initCarousel();
		},
		methods: {
			highlightMatches(string, query) {
				if (!query) {
					return '';
				}
				return string.replace(new RegExp(query, 'gi'), match => {
					return '<b>' + match + '</b>';
				});
			},
			initCarousel() {
				jQuery('.search-drop').fadeIn(300, () => {
					if (this.is_show_products && this.search_items.products.length > 3) {
						jQuery('.search-drop').find('.js-app-carousel').not('.slick-initialized').slick({
							infinite: true,
							slidesToShow: 3,
							slidesToScroll: 3,
							variableWidth: true
						});
					}
					jQuery('.search-drop').find('.js-app-carousel').attr('id', 'js-search-gallery-start');
				});
			}
		}
	};
</script>