import Vue from 'vue';

function format_money(value, options) {
	let result;

	if(options.decimals && typeof options.decimals === 'string') {
		options.decimals = parseInt(options.decimals);
	}

	if(options.multiplier && options.multiplier.length) {
		value = value * parseFloat(options.multiplier);
	}

	if(options.rate) {
		value = parseFloat( value / parseFloat(options.rate) );
	}

	result = Math.abs(value).toFixed(options.decimals);

	if(isNaN(result)) {
		throw new Error(`money filter's value is not a number: ${result}`);
	}

	if(options.thousands_separator) {
		result = result.replace(/(\d)(?:(?=\d+(?=[^\d.]))(?=(?:\d{3})+\b)|(?=\d+(?=\.))(?=(?:\d{3})+(?=\.)))/g, '$1' + options.thousands_separator);
	}

	if(options.currency) {
		if(options.currency_position === 'left') {
			result = options.currency + result;
		} else {
			result = result + '\xa0' + options.currency;
		}
	}

	if(value < 0) {
		result = '-' + result;
	}
	return result;
}

Vue.filter('clog', (value) => {
	const method = typeof value === 'object' ? 'dir' : 'log';
	return method;
});

Vue.filter('capitalize', (value) => {
	if (!value) {
		return '';
	}
	value = value.toString();
	return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('money', (value, options) => {
	const value_type = typeof value;
	const value_type_is_valid = (value_type === 'number' || value_type === 'string');
	let result;
	let exchange;
	let rate;

	if (!value_type_is_valid) {
		return '';
	}

	if( !options ) {
		options = {};
	}

	const defaults = {
		currency: '$',
		currency_position: 'left',
		thousands_separator: ',',
		multiplier: '',
		rate: '',
		decimals: 2
	};

	if (options.rate) {
		rate = options.rate;
		options.rate = '';
	}

	options = Object.assign(defaults, options);

	if( window.SiteSettings && window.SiteSettings.Currency ) {
		Object.keys( window.SiteSettings.Currency ).forEach(function (key) {
			if(!options[key]) {
				options[key] = window.SiteSettings.Currency[key];
			}
		});
	}

	result = format_money(value, options);

	if( window.SiteSettings && window.SiteSettings.CurrencyExchange ) {
		let exchange_options = window.SiteSettings.CurrencyExchange;
		if (rate) {
			exchange_options.rate = rate;
		}
		exchange = format_money(value, exchange_options);
		result += '\xa0/ ' + exchange;
	}

	return result;
});

Vue.filter('phone_number', (phone, showIntCode = true) => {
	if (!phone) return '';

	const cleaned = `${phone}`.replace(/\D/g, '');
	const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	const intCode = showIntCode ? match[1] ? '+1 ' : '' : '';

	return match ? `${intCode}(${match[2]}) ${match[3]}-${match[4]}` : phone;
});